.containerContent {
    padding: 20px
}

.wrapp-register {
    background-color: #03ab68;
    color: #FFF;
    padding: 4px;
    border-radius: 4px;
}

.wrapp-not-register {
    background-color: #f01c55;
    color: #FFF;
    padding: 4px;
    border-radius: 4px;
}