.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
	margin-bottom: 10px !important;
	margin-top: 10px !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
	color: black !important;
}

.css-1ex1afd-MuiTableCell-root {
	padding: 5px 15px;
}

.wrappFields {
	width: 200px;
}